import React, { useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import Calculator from "../Calculator";
import axios from "axios";

const EditorMahasiswa = ({ initialContent, onChange }) => {
  const [showCalculator, setShowCalculator] = useState(false);

  const openCalculator = () => setShowCalculator(true);
  const closeCalculator = () => setShowCalculator(false);
  const API_URL = process.env.NODE_ENV === 'production'
  ? process.env.REACT_APP_API_URL_PROD
  : process.env.REACT_APP_API_URL_LOCAL;
  return (
    <div className="App">
            {showCalculator && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg relative">
            <button
              onClick={closeCalculator}
              className="absolute top-2 right-2 text-red-600 text-xl font-bold"
            >
              &times;
            </button>
            <Calculator />
          </div>
        </div>
      )}
      <Editor
        value={initialContent}
        init={{
          height: 500,
          menubar: true,
                    license_key: 'gpl',
          plugins: "eqneditor visualchars image table media wordcount link autoresize code pagebreak lists",
          toolbar: "undo redo customImageGallery drawioButton calculatorButton table | eqneditor image media numlist bullist | formatselect bold italic backcolor removeformat pagebreak | alignleft aligncenter alignright alignjustify | link outdent indent downloadImage",
          image_title: true,
          table_toolbar: "tableprops tabledelete | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol",
          automatic_uploads: true,
          file_picker_types: "image",
          file_picker_callback: async (callback, value, meta) => {
            const input = document.createElement("input");
            input.setAttribute("type", "file");
            input.setAttribute("accept", "image/*");
          
            input.onchange = async function () {
              const file = this.files[0];
              const formData = new FormData();
              formData.append("file", file);
          
              try {
                // Kirim gambar ke server
                const response = await axios.post(`${API_URL}/upload-image`, formData, {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                });
          
                // Jika server mengembalikan URL gambar
                if (response.data && response.data.location) {
                  // Tampilkan gambar di editor menggunakan URL
                  callback(response.data.location, { alt: file.name });
                } else {
                  alert("Gagal mengupload gambar. Server tidak mengembalikan URL gambar.");
                }
              } catch (error) {
                console.error("Error uploading image:", error);
                alert("Terjadi kesalahan saat mengupload gambar.");
              }
            };
          
            input.click();
          },
          
          
          setup: (editor) => {
            editor.ui.registry.addButton("drawioButton", {
              text: "Insert Diagram",
              onAction: () => {
                const drawioUrl = "https://app.diagrams.net/?noSave=1#R"; 
                const win = window.open(drawioUrl, "DrawIO", "width=1000,height=800");
          
                const messageListener = (event) => {
                  if (event.origin === "https://app.diagrams.net") {
                    const diagramLink = event.data; 
                    
                    editor.insertContent(
                      `<img src="${diagramLink}" alt="Diagram from Draw.io" data-edit-url="${diagramLink}" style="max-width: 100%; height: auto;" />`
                    );
                    win.close();
                  }
                };
          
                window.addEventListener("message", messageListener, false);
                win.onbeforeunload = () => {
                  localStorage.clear();
                  window.removeEventListener("message", messageListener);
                };
              },
            });
            editor.ui.registry.addButton("calculatorButton", {
              text: "Calculator",
              onAction: () => {
                openCalculator();
              },
            });
            editor.ui.registry.addButton("downloadImage", {
              text: "Download Image",
              onAction: () => {
                const selectedImg = editor.selection.getNode();
                if (selectedImg.nodeName === "IMG" && selectedImg.src) {
                  const link = document.createElement('a');
                  link.href = selectedImg.src;
                  link.download = 'downloaded-image.svg'; 
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                } else {
                  alert("Silakan pilih gambar terlebih dahulu.");
                }
              },
            });

            editor.on("click", (event) => {
              const target = event.target;
              if (target.nodeName === "IMG" && target.getAttribute("data-edit-url")) {
                const editUrl = target.getAttribute("data-edit-url");
                const win = window.open(editUrl, "EditDiagram", "width=1000,height=800");
                window.addEventListener("message", (event) => {
                  if (event.origin === "https://app.diagrams.net") {
                    const updatedDiagramLink = event.data;
                    target.src = updatedDiagramLink;
                    target.setAttribute("data-edit-url", updatedDiagramLink);
                    win.close();
                  }
                }, false);
              }
            });
          },

        }}
        onEditorChange={(content) => {
          onChange(content);
        }}
      />
    </div>
  );
};

export default EditorMahasiswa;
